<template>
  <div class="shenshi-wrapper">
    <h2 class="shenshi-title">免责声明</h2>
    <p class="shenshi-p">本公众号内容由北京甚智医学科技有限公司提供，仅作为医学信息沟通与交流使用，供使用者参考。由于肾脏疾病专业知识的不断增加和公司处于发展过程中，本公司不对其准确性、时效性和完整性作任何保证。</p>
    <p class="shenshi-p">在法律允许的范围内，本公司在此声明，不对您因信赖、使用本微信信息导致的损失承担任何责任，包括但不限于因微信内容的任何错误或疏漏导致的损失，任何直接的、间接的、偶然性或惩罚性的损害赔偿。</p>
    <p class="shenshi-p">本公众号涉及的任何医疗信息仅作为一般知识性内容提供，不用于医疗保健或疾病诊断。如您有涉及到任何关于疾病咨询的问题或情况，请务必向医疗卫生专业人士寻求帮助。本公众号所涉及的有关本公司产品信息，非药品广告，仅供参考。</p>
    <p class="shenshi-p">您在本公众号上读到或看到的任何信息均受著作权保护，为北京甚智医学科技有限公司或其第三方提供者所有。您可以自由访问和使用本公众号，如转发、发表任何评论、意见或建议等。但未经北京甚智医学科技有限公司许可，不得以商业目的，修改、转载或传播、利用信息。</p>
    <p class="shenshi-p">本公司提醒您：在使用本公众号前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用本公众号，但如果您访问或使用本公众号，表明您已阅读、理解并同意以上免责条款。北京甚智医学科技有限公司保留在其认为必要时修改本条款的权利，并对以上条款具有最终解释权。</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">

</style>